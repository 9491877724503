.Featured-Left-Image {
  background-color: white;
}

.Featured-Left-Image img {
  transform: rotate(90deg);
}

.Featured-Right-Image {
  overflow: hidden;
  min-height: 500px;
}

.Featured-Right-Image img {
  animation: zoomInOut 9s infinite forwards;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  transition: all 8s linear;
}

.Hero-slider .slick-slide {
  height: 100vh;
}

.slick-prev:before {
  content: "" !important;
}

.slick-next:before {
  content: "" !important;
}

@media screen and (max-width: 913px) {
  .slick-prev {
    width: 30px !important;
    height: 30px !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    z-index: 1 !important;
    left: 5px !important;
  }

  .slick-next {
    width: 30px !important;
    height: 30px !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    z-index: 1 !important;
    right: 5px !important;
  }

  .slick-prev {
    background-image: url("../../assets/FeaturedImages/leftArrow.png") !important;
  }

  .slick-next {
    background-image: url("../../assets/FeaturedImages/rightArrow.png") !important;
  }
}

@media screen and (min-width: 1024px) {
  .Hero-slider .slick-prev {
    left: 50px !important;
    z-index: 9999 !important;
  }

  .Hero-slider .slick-next {
    right: 50px !important;
    z-index: 9999 !important;
  }
}

/* 
.Hero-slider .slick-prev:before {
  content: '←' !important;
  font-size: 30px !important;
} */

.Hero-slider {
  overflow-y: hidden;
  overflow-x: hidden;
}

/* .slick-dots li.slick-active button:before {
  opacity: .75;
  color: white !important;
} */

.slick-prev,
.slick-next {
  width: 30px !important;
  height: 30px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  z-index: 1 !important;
}

.slick-prev {
  background-image: url("../../assets/FeaturedImages/leftArrow.png") !important;
}

.slick-next {
  background-image: url("../../assets/FeaturedImages/rightArrow.png") !important;
}

.slick-slide {
  visibility: hidden;
}

.slick-slide.slick-active {
  visibility: visible;
}


.Feature-Architecture {
  position: absolute;
  bottom: 0;
  right: 0;
}

.Feature-Architecture button {
  background-color: white;
  color: black;
  font-size: 30px;
  text-transform: uppercase;
  padding: 20px 30px;
  border: none;
}

.feature-icons {
  position: absolute;
  left: 0;
  bottom: 0;
  color: white;
}

.feature-icons img {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

/* -----------MEDIA QUERIES------------- */

@media screen and (min-width: 768px) {
  .Featured-Left-Image {
    background-color: white;
    writing-mode: vertical-lr;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }
}

@media screen and (max-width: 912px) {
  .Featured-Left-Image {
    display: none;
  }

  .Featured-Right-Image {
    overflow: hidden;
    min-height: 300px;
  }

  .feature-icons {
    display: none;
  }
}

@media screen and (max-width: 768px) and (min-height: 1024px) {
  .Featured-Right-Image {
    overflow: hidden;
    min-height: 300px;
  }
}

@media screen and (max-width: 1180px) and (min-height: 820px) {
  .Featured-Right-Image {
    overflow: hidden;
    min-height: 350px;
  }
}

.Hero-slider h1 {
  font-size: 48px;
  font-weight: 700;
  text-transform: capitalize;
}

.quote-btn {
  font-size: 20px;
  background-color: black;
  padding: 8px 35px;
  border: none;
  color: white;
}