.aboutUs {
  background-image: url("../../assets/AboutUsImages/headerW.webp");
  background-size: cover;
  background-repeat: no-repeat;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .aboutUs {
    background-image: url("../../assets/AboutUsImages/AboutMobile.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: -184px 31%;
  }
}

.hr-style {
  border-top: 1px solid black;
}

.bg-cover {
  background-size: cover;
  min-height: 500px;
}

@media screen and (max-width: 767px) {
  .bg-cover {
    background-size: cover;
    min-height: 300px;
  }
}

.description-ethicalessence {
  font-size: 17px;
}

/* -------------- */

.First {
  background-image: url("https://lirp.cdn-website.com/badf92b5/dms3rep/multi/opt/roof-bg-1920w.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.spacer {
  background-color: rgb(239, 239, 239);
  padding-bottom: 15px !important;
}

.spacer-right {
  padding: 15px;
  background-color: rgb(239, 239, 239);
}

.spacerCol {
  display: flex;
  align-items: end;
  position: relative;
}

.inner-right {
  background-color: rgb(239, 239, 239);
  padding-bottom: 15px;
}

@media (min-width: 960px) {
  .spacer {
    max-width: 80%;
    margin-bottom: -50px;
    margin-left: -80px;
  }

  .spacer-right {
    margin-bottom: -50px;
    margin-right: -80px;
  }

  .spacerCol {
    padding-left: 200px !important;
  }
}

@media only screen and (max-width: 600px) {
  .spacer,
  .spacer-right {
    margin: 0;
    padding: 0;
  }

  .spacerCol {
    padding: 0;
  }

  .inner-right {
    margin: 0;
  }
}

@media screen and (max-width: 500px) {
  .ethics-second {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 500px) {
  .ethics-fourth {
    display: flex;
    flex-direction: column-reverse;
  }
}
