#testimonial-header {
  background-image: url("../../assets/ClientTestimonyImages/testimonialFullBannerW.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Testi-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#testimonial-header img {
  height: 50px;
}

.testimony-review {
  font-size: 14px;
  line-height: 20px;
}

#testimonial-header .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black !important;
}
