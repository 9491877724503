.wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.item {
  margin-top: 10px;
  background-color: white;
  margin-bottom: 5px;
  padding: 0;
}

.title {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.title-inner {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.title span {
  font-size: 40px;
}

.content {
  color: black;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.show {
  color: auto;
  max-height: 9999px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.readmore-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: white;
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 30px;
  padding: 10px 25px;
}

.readmore-link:hover {
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: white;
}

.readmore-link svg {
  margin-right: 5px;
}

.description-ethicalessence {
  text-align: justify;
  font-size: 17px;
  line-height: 1.5;
  font-weight: 300;
  color: white !important;
}

.subheading {
  font-weight: 600;
  font-size: 400;
  line-height: 1.3;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-size: 40px;
  color: white !important;
}

@media screen and (max-width: 500px) {
  .ethical-second {
    display: flex;
    flex-direction: column-reverse;
  }

  .ethical-fourth {
    display: flex;
    flex-direction: column-reverse;
  }
}
