.service-content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 5rem;
  padding-left: 5rem;
  color: #ffffff;
}

.content-subheading {
  font-size: 25px;
  line-height: 25px;
}

.content-description {
  font-weight: 200;
}

.service-readmore-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: white;
  font-size: 15px;
}

.service-readmore-link:hover {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: white;
}

.service-readmore-link svg {
  margin-right: 5px;
}

.container-fluid {
  position: relative;
}

.main-Banner {
  height: 600px;
  background-size: cover;
}

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 20px;
}

.description {
  width: 90%;
}

.textCol {
  padding-left: 80px;
}

@media (max-width: 280px) {
  .textCol {
    padding-left: 20px;
  }
}

.info-content {
  display: none;
}

@media screen and (max-width: 700px) {
  .Top-Main {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .info-content {
    display: block;
  }
}

.Top-Main .slick-prev {
  background-image: url("../../assets/FeaturedImages/leftArrow.png") !important;
}

.Top-Main.slick-next {
  background-image: url("../../assets/FeaturedImages/rightArrow.png") !important;
}

.Top-Main .slick-prev {
  margin-left: 45% !important;
  top: 90%;
  height: 20px !important;
}

.Top-Main .slick-next {
  margin-right: 45% !important;
  top: 90%;
  height: 20px !important;
}

@media only screen and (max-width: 920px) and (min-width: 701px) {
  .Top-Main .slick-prev {
    margin-left: 40% !important;
    top: 90%;
    height: 20px !important;
  }

  .Top-Main .slick-next {
    margin-right: 40% !important;
    top: 90%;
    height: 20px !important;
  }
}
