body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Rubik-Bold";
  src: local("Rubik-Bold"),
    url("./Font/Rubik-Bold/Rubik-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Rubik-ExtraBold";
  src: local("Rubik-ExtraBold"),
    url("./Font/Rubik-ExtraBold/Rubik-ExtraBold.ttf") format("truetype");
  font-weight: 800; /* Specify the desired font weight, e.g., 800 for "ExtraBold" */
  font-style: normal;
}

@font-face {
  font-family: "Rubik-Light";
  src: local("Rubik-Light"),
    url("./Font/Rubik-Light/Rubik-Light.ttf") format("truetype");
  font-weight: 300; /* Specify the desired font weight, e.g., 300 for "Light" */
  font-style: normal;
}

@font-face {
  font-family: "Rubik-Regular";
  src: local("Rubik-Regular"),
    url("./Font/Rubik-Regular/Rubik-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; /* Specify the desired font style, e.g., "italic" */
}

@font-face {
  font-family: "Felixti";
  src: local("Felixti"), url("./Font/Felixti/Felixti.TTF") format("truetype");
  font-weight: normal;
  /* Specify the desired font style, e.g., "italic" */
}
