ul {
  padding-left: 0px !important;
  list-style: none;
}

ul li {
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 0.3px;
}

.f-menu li {
  margin-bottom: 10px;
}

.FbLogo {
  width: 21px;
  height: 20px;
}

.InstaLogo {
  width: 21px;
  height: 20px;
}

section span {
  cursor: pointer;
}

.Footer p {
  font-size: 14px;
  text-decoration: none;
}

.anchortagfooter-style {
  text-decoration: none;
  color: black;
}

.anchortagfooter-style:hover {
  text-decoration: none;
  color: black;
}
