.offcanvas {
  width: 100% !important;
  height: 100% !important;
  cursor: pointer;
  overflow-y: scroll;
  color: #6b6b6b;
  background-image: url("../../assets/PortfolioImages/bg-architecture-agency\ copyW.webp");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  z-index: 9999 !important;
}

.Horizontal-line {
  color: #6b6b6b;
}

.Search-Icon {
  cursor: pointer;
}

.offcanvas-title {
  color: #6b6b6b;
}

.items {
  font-size: 25px;
  color: #6b6b6b;
}

#nav-List {
  line-height: 30px;
}

#nav-List ul:hover {
  color: white;
}

.floating-icons {
  display: flex;
  align-items: center;
  justify-content: start;
  color: #6b6b6b;
}

.floating-icons img {
  height: 30px;
}

.icons {
  cursor: pointer;
  color: #6b6b6b;
}

/* -------------MEDIA QUERIES FOR RESPONSIVENESS-------------- */
@media screen and (min-width: 1024px) {
  #Floating-Menu {
    height: 10vh;
    width: 100%;
    position: absolute;
    top: 55%;
    padding-left: 20vh;
    color: #6b6b6b;
  }
}

@media screen and (max-width: 912px) {
  .floating-icons {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-items: flex-start;
    color: #6b6b6b;
  }
}

@media screen and (min-width: 1024px) {
  #nav-List {
    margin-left: 60%;
    margin-top: -30% !important;
    color: #6b6b6b;
  }
}

@media screen and (max-width: 820px) {
  .floating-icons {
    padding-top: 10px;
    color: #6b6b6b;
  }
}

span {
  color: #6b6b6b;
}

@media screen and (max-width: 280px) {
  .icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icons img {
    height: 25px;
    margin-left: 0px !important;
  }
}

/* -------------FOR FONT SIZES----------- */

@media screen and (min-width: 768px) and (max-width: 912px) {
  .offcanvas {
    font-size: 35px;
    color: #6b6b6b;
  }
}

/* -----------FOR DISPLAY NONE------------ */

@media screen and (max-width: 767px) {
  .first {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .second {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .third {
    display: none;
  }
}

.anchortag-style {
  text-decoration: none;
  color: #6b6b6b;
}

.anchortag-style:hover {
  text-decoration: none;
  color: white;
}

.myClass {
  background-color: transparent !important;
}
