.font-face-rb {
  font-family: "Rubik-Bold";
}

.font-face-re {
  font-family: "Rubik-ExtraBold";
}

.font-face-rl {
  font-family: "Rubik-Light";
}

.font-face-rr {
  font-family: "Rubik-Regular";
}

.font-face-fl {
  font-family: "Felixti";
}
