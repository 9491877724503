.ltn__header-transparent .top-area {
  background-color: transparent;
}

.ltn__header-top-area {
  border-bottom: 1px solid;

  border-color: #ccc;
  z-index: 999;
  position: relative;
}

.ltn__header-top-area a {
  color: #fff;
  text-decoration: none;
}

.ltn__top-bar-menu > ul {
  padding: 0;
  margin: 0;
}

.ltn__top-bar-menu > ul > li {
  list-style: none;
  display: inline-block;
  margin: 0 30px 0 0;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}

.ltn__social-media ul {
  margin: 0;
  padding: 0;
}

.ltn__header-top-area .ltn__social-media ul li {
  font-size: 14px;
  margin: 0 0 0 0;
}

.ltn__social-media ul li {
  list-style: none;
  display: inline-block;
  margin: 0 15px 0 0;
}

.ltn__header-options i,
.ltn__header-top-area i {
  font-weight: bold;
}

.icon-img {
  height: 20px;
  width: 20px;
}
