.section-padding {
  padding: 100px 0;
}

.section-dark {
  background-image: url("../../assets/ContactFormImages/ContactFormBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.form-control,
.form-group .form-control {
  border: 0;
  background-image: linear-gradient(#009688, #009688),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center -webkit-calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
}

.form-group {
  position: relative;
}

.form-group textarea {
  resize: none;
}

.form-group {
  padding-bottom: 7px;
  margin: 28px 0 0 0;
}

.form-group label.control-label {
  font-size: 12px;
  line-height: 1.07142857;
  font-weight: 400;
  margin: 16px 0 0 0;
}

.form-group.label-floating.is-focused label.control-label,
.form-group.label-floating:not(.is-empty) label.control-label,
.form-group.label-static label.control-label {
  top: -30px;
  left: 0;
  font-size: 12px;
  line-height: 1.07142857;
}

#contactForm {
  margin-top: -10px;
}

#contactForm .form-group label.control-label {
  color: white;
  font-size: 14px;
}

.form-control:focus,
.form-control:active {
  background: transparent !important;
  box-shadow: 0 0 5px #d2d2d2 !important;
}

.section-title {
  color: white;
}

.section-subtitle {
  color: white;
  font-weight: 300;
}

.section-description {
  color: white;
  font-size: 14px;
  line-height: 20px;
}

.find-widget {
  color: white;
  font-size: 14px;
}

.btn-send {
  background-color: white;
  color: black;
  font-weight: 500;
  font-size: 14px;
  padding: 3px;
  width: 300px;
}

.form-control {
  color: white !important;
}

.section-description {
  width: 90%;
}

@media screen and (max-width: 280px) {
  .btn-send {
    width: 200px;
  }
}