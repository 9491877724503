.error-container {
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.error-container h1 {
  font-size: 120px;
  font-family: "Roboto-Bold";
}

.error-container p {
  font-size: 25px;
  font-family: "Roboto-Regular";
}
