.BBQ-Area {
  background-image: url("../../assets/BBQAreaImages//headerW.webp");
  background-size: cover;
  background-repeat: no-repeat;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pergolas {
  background-image: url("../../assets/pergolas/headerW.webp");
  background-size: cover;
  background-repeat: no-repeat;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.glassRooms {
  background-image: url("../../assets/GlassRoomsImages/headerW.webp");
  background-size: cover;
  background-repeat: no-repeat;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cncCutPanel {
  background-image: url("../../assets/CncCutPanelImages/headerW.webp");
  background-size: cover;
  background-repeat: no-repeat;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carParkingShades {
  background-image: url("../../assets/CarParkingShades/headerW.webp");
  background-size: cover;
  background-repeat: no-repeat;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aluWindowsDoors {
  background-image: url("../../assets/AliminiumDoorsAndWindowsImages/headerW.webp");
  background-size: cover;
  background-repeat: no-repeat;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .BBQ-Area {
    background-image: url("../../assets/BBQAreaImages/BarBQMobileW.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media screen and (max-width: 500px) {
  .pergolas {
    background-image: url("../../assets/pergolas/PergolasMobileW.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: -161px 31%;
  }
}
@media screen and (max-width: 500px) {
  .glassRooms {
    background-image: url("../../assets/GlassRoomsImages/GlassRoomMobileW.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media screen and (max-width: 500px) {
  .cncCutPanel {
    background-image: url("../../assets/CncCutPanelImages/CNCMobileW.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media screen and (max-width: 500px) {
  .carParkingShades {
    background-image: url("../../assets/CarParkingShades/CarParkingMobileW.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 500px) {
  .aluWindowsDoors {
    background-image: url("../../assets/AliminiumDoorsAndWindowsImages/DoorsMobileW.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
