.Top-Main2.slick-prev {
  background-image: url("../../assets/FeaturedImages/leftArrow.png") !important;
}

.Top-Main2.slick-next {
  background-image: url("../../assets/FeaturedImages/rightArrow.png") !important;
}

.Top-Main2 .slick-prev {
  margin-left: 10% !important;
  top: 90%;
  height: 20px !important;
}

.Top-Main2 .slick-next {
  margin-right: 10% !important;
  top: 90%;
  height: 20px !important;
}

.read-btn {
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: white;
  font-size: 15px;
}

.read-btn:hover {
  text-decoration: none;
  cursor: pointer;
  color: white;
}

.read-btn svg {
  margin-right: 5px;
}
